import { defineStore } from 'pinia';
import axios from "axios";

export const useStepsStore = defineStore('stepsStore', {
    state: () => ({
        questions: [],
        maxSteps: 0,
        loading: false,
        error: null,
        pickeds: {},
        picked: '',
        correctAnswer: '',
        correctAnswers: {},
        correctAnswersSum: 0,
        userData: {},
        userResult: '',
        loadingPage: true
    }),
    getters: {
        showActiveInput(state){
            return (index, answer) => state.pickeds[index] == answer
        }
    },
    actions: {
        async fetchQuestions() {
            this.questions = [];
            this.loading = true;
            axios
                .get('https://bo.pitstoplecce.it/?r=survey_question/survey_questions')
                .then(response => {
                    console.log(response);
                    this.questions = response.data;
                    this.maxSteps = this.questions.length - 1;
                    for(let i = 0; i < this.questions.length; i++ ){
                        this.correctAnswers[this.questions[i].id] = this.questions[i].correct_answer;
                    }
                })
                .catch((error) => this.error = error)
                .finally(() => this.loading = false);

        },
        savePickedValue(e){
            if(e) {
                if(e.target.id.length == 3){
                    this.pickeds[e.target.id[0]] = e.target.id.slice(2);
                } else {
                    this.pickeds[e.target.id[0] + e.target.id[1]] = e.target.id.slice(3);
                }
            }
            // this.picked = this.pickeds[e.target.id[0]];
            // this.correctAnswer = this.questions[e.target.id[0] - 1].correct_answer;
        },
        saveResults(){
            for(let i of Object.keys(this.correctAnswers)){
                if(this.correctAnswers[i] == this.pickeds[i]){
                    this.correctAnswersSum++;
                }
            }
        },
        saveUserData(userGender, userAge, comuneResidenza, drivingLicense, typeDrivingLicense){
            this.userData.userGender = userGender;
            this.userData.userAge = userAge;
            this.userData.comuneResidenza = comuneResidenza;
            this.userData.drivingLicense = drivingLicense;
            this.userData.typeDrivingLicense = typeDrivingLicense;
        },
        saveUserResult(goodres){
            this.userResult = goodres;
        },
        saveSurvey(){
            let data = new FormData();
            data.append("user_gender", this.userData.userGender);
            data.append("user_age", this.userData.userAge);
            data.append("comune_residenza", this.userData.comuneResidenza);
            data.append("driving_license", this.userData.drivingLicense);
            data.append("type_driving_license", this.userData.typeDrivingLicense);
            data.append("riflessi_gioco_ok", this.userResult);

            this.questions.forEach((item, i) => {
                i++;
                data.append("question_" + i + "_id", item.id);
                data.append("answer_" + i, this.pickeds[item.id]);
            });

            for (var key of data.entries()) {
                console.log(key[0] + ', ' + key[1]);
            }
            
            axios({
                method: "post",
                url: "https://bo.pitstoplecce.it/?r=survey_saved%2Fsurvey_save",
                data: data,
                headers: { "Content-Type": "multipart/form-data" },
            })
            .then(response => {
                console.log(response);
            })
            .catch((error) => this.error = error)
            .finally(() => {
                this.loading = false;
                this.pickeds = {};
            });
        }
    }
});
