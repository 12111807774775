<template>
    <div class="map-page">
        <div class="page-padding">
            <h1>PitStop Points</h1>
        </div>
        <div class="map" v-if="initmap">
            <div id="map" @loadstart="initmap" data-point="0"></div>
        </div>
        <MapModal />
    </div>
</template>

<script>
    import { useStepsStore } from '../stores/StepsStore.js';
    import { storeToRefs } from 'pinia'
    import axios from "axios";
    import MapModal from './../components/MapModal.vue';
    import { loadScript, removeScript } from '../load-script'
    
    export default {
        components: {
            MapModal
        },
        setup(){
            var directionsOptions = {
                polylineOptions: {
                    strokeColor: '#00A3D8'
                },
                suppressMarkers: true
            }
            const directionsRenderer = new google.maps.DirectionsRenderer(directionsOptions);
            const directionsService = new google.maps.DirectionsService();
            var map, markerCluster, all_points = [], all_text_points = [], iam, lat = 0, lng = 0, marketlat = 0, markerlng = 0, myPointerInited = false;

            // var onSuccess = function(position) {
            //     lat = position.coords.latitude;
            //     lng = position.coords.longitude;
            //     setMyPointer(lat, lng);
            //     var mapCenter = new google.maps.LatLng(lat, lng);
		    //     // map.setCenter(mapCenter);
                
            // };
            // function onError(error) {
            //     document.getElementById('mapArrow').style.display = "none";
            // }

            // navigator.geolocation.getCurrentPosition(onSuccess, onError);
            
            function setMyPointer(lat, lng) {
                if(lat != 0 && lng != 0 && !myPointerInited) {
                    iam = new google.maps.Marker({
                        position: new google.maps.LatLng(lat, lng),
                        map: map,
                        zIndex: 9999,
                        icon: {
                            url: "../src/assets/icons/dove_sono.svg",
                            scaledSize: new google.maps.Size(25, 25)
                        }
                    });

                    var mapCenter = new google.maps.LatLng(lat, lng);
		            map.setCenter(mapCenter);

                    myPointerInited = true;
                }
            }
            function initmap(){
                var latlng = new google.maps.LatLng(40.3551560942811, 18.174979054387556);
                var mapOptions = {
                    zoom: 14,
                    center: latlng,
                    disableDefaultUI: true,
                    styles: [
                        {
                            featureType: "poi.business",
                            stylers: [{ visibility: "off" }],
                        }
                    ]
                }
                map = new google.maps.Map(document.getElementById('map'), mapOptions);
                directionsRenderer.setMap(map);
            }
            
            function addMarkers(){
                axios.get('https://bo.pitstoplecce.it/?r=location/locations').then(resp => {
                    const point = resp.data;
                    console.log(point);
                    point.forEach(function (element) {
                        all_text_points.push(element);
                        var marker = new google.maps.Marker({
                            map: map,
                            position: new google.maps.LatLng(element.lat, element.long),
                            icon: {
                                url: 'assets/icons/pin_n.svg',
                                scaledSize: new google.maps.Size(50, 50)
                            }
                        });
                        all_points.push(marker);
                        marker.addListener("click", () => {
                            navigator.vibrate(100);
                            // marketlat
                            document.getElementById('slideUp').click();
                            marketlat = element.lat;
                            markerlng = element.long;
                            // map.setZoom(16);
                            map.setCenter(marker.getPosition());
                            document.getElementById('mapname').innerHTML = element.location_name;
                            document.getElementById('mapaddress').innerHTML = element.address;
                            document.getElementById('pointdesc').innerHTML = element.location_desc;
                            openPointBar();
                            if(typeof(iam) === 'object'){
                                calculateAndDisplayRoute(directionsService, directionsRenderer);
                            }
                            document.getElementById('pointdesc').style.height = 'auto'; 
                            changeMarkerSize();
                            marker.setIcon({
                                url: 'assets/icons/pin_n.svg',
                                scaledSize: new google.maps.Size(70, 70),
                                labelClass: 'marker-label'
                            });
                            marker.setZIndex(10000);
                        });
                    });

                    if(lat == 0 && lng == 0){
                        var mapLat = 0;
                        var mapLong = 0;
                        for(let i = 0; i < all_text_points.length; i++){
                            mapLat += parseFloat(all_text_points[i].lat);
                            mapLong += parseFloat(all_text_points[i].long);
                        }
                        // mapLat = mapLat / all_text_points.length;
                        // mapLong = mapLong / all_text_points.length;
                        // var mapCenter = new google.maps.LatLng(mapLat, mapLong);
		                // map.setCenter(mapCenter);
                    }
                    return point;
                });
            }

            function changeMarkerSize() {
                for (var i = 0; i < all_points.length; i++) {

                    all_points[i].setIcon({
                        url: 'assets/icons/pin_n.svg',
                        scaledSize: new google.maps.Size(50, 50),
                        labelClass: 'marker-label'
                    });
                    all_points[i].setZIndex(10);
                };
                console.log(all_points);
            }

            function calculateAndDisplayRoute(directionsService, directionsRenderer) {
                directionsService
                    .route({
                        origin: new google.maps.LatLng(lat, lng),
                        destination: new google.maps.LatLng(marketlat, markerlng),
                        // travelMode: google.maps.TravelMode[selectedMode],
                        travelMode: google.maps.TravelMode.DRIVING,
                    })
                    .then((response) => {
                        directionsRenderer.setDirections(response);
                        var map_km = response.routes[0].legs[0].distance.text;
                        document.getElementById('distance').innerHTML = map_km;
                       
                    })
                    .catch((e) => {
                        console.log(e);
                    });
            }

            function openPointBar(){
                
                document.getElementsByClassName('map-modal')[0].style.marginTop = "0px";
                document.getElementsByClassName('map-modal_block')[0].style.marginBottom = "0px";
            }

            const { loadingPage} = storeToRefs(useStepsStore())

            return {
                initmap,
                addMarkers,
                openPointBar,
                setMyPointer,
                changeMarkerSize,
                loadingPage
            }
        },
        mounted() {
            this.initmap(),
            this.addMarkers(),
            this.loadingPage = false
        },
        data(){
            return {
                mainMapIcon: "./../assets/icons/map.svg"
            }
        }
    }
</script>

<style scoped>
    .map-page{
        position: fixed;
        width: 100%;
        height: 100%;
    }
    .map-page h1{
        margin: 0 0 85px;
    }
    .map{
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: var(--color_main_blue);
    }
    #map{
        bottom: 0;
        left: 0;
        width: calc(100% - 230px);
        height: calc(100% - 650px);
    }
</style>