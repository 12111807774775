<template>
    <div id="splash" @click="handleClick">
        <!-- <div :style="{ backgroundImage: `url(${img})` }" id="splash" @click="handleClick"> -->
        <video :src="video" id="splashvideo" autoplay  playsinline loop muted></video>
        <!-- <img :src="logo" /> -->
        <img :src="logo" />
        <a class="main-butt">Entra nel mondo<br>della guida sobria</a>
    </div>
    
    
</template>
  
  <script>
  export default {
          setup(){
            function showSplash(){
                document.getElementById('splash').style.display = 'block';
            }
            return {
                showSplash
            }
          },
          methods: {
            handleClick: () => {
                document.getElementById('splash').style.display = 'none';
            },
        },
          mounted() {
              this.showSplash()
          },
          data() {
              return{
                  img: "./../assets/icons/splash-img.webp",
                  logo: "./../assets/icons/plw.svg",
                  video: "./../assets/clip_pitstop_verticale.mp4"
              }
          }
      }
  </script>
  
  <style scored>
    #splash {
        width: 100vw;
        height: 100%;
        position: fixed;
        left: 0;
        object-fit: cover;
        background-repeat: no-repeat;
        z-index: 12;
        display: none;
    }
    #splashvideo {
        width: 100vw;
        height: calc(100vh - 300px);
        position: fixed;
        left: 0;
        object-fit: cover;
        background-repeat: no-repeat;
    }
    #splash img {
        margin: 140px 300px;
        width: calc(100% - 600px);
        position: relative;
    }
    #splash a {
        position: relative;
        width: 532px;
        margin: 0 auto;
        top: 40vh;
        height: 216px;
        font-size: 48px;
        line-height: 57px;
        border-radius: 30px;
    }
  </style>