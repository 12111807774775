<template>
    <div class="page-padding">
        <div v-for="data in data" :key="data.id">
            <h1 class="news-title">{{ data.page_title }}</h1>
            <img :src="data.image_url" alt="">
            <div v-html="data.page_desc"></div>
        </div>
    
        <Loader v-if="loading" />
    
        <ErrorAxios v-if="error" />
    
        <div v-if="currentArticles" class="news">
            <div v-for="article in currentArticles" :key="article.id" class="news_article-block">
                <div class="news_article">
                    <div class="news_article-img">
                        <router-link :to="{ name: 'NewsDetal', params: {id: article.id} }">
                            <img class="news_img" :src="article.image_url" :alt="article.news_title">
                        </router-link>
                    </div>
                    <div class="news_article-items">
                        <span class="news_data">{{ article.date_start }}</span>
                        <router-link :to="{ name: 'NewsDetal', params: {id: article.id} }">
                            <h3 class="news_title">{{ article.news_title }}</h3>
                        </router-link>
                        <p class="news_text" v-html="article.news_desc"></p>
                    </div>
                </div>
            </div>
            <button @click="loadMoreArticles" v-if="moreArticles" class="show-more_butt">Mostra di più</button>
        </div>
    </div> 
</template>
  
<script>
    import { storeToRefs } from 'pinia'
    import axios from "axios";
    import { useStepsStore } from './../../stores/StepsStore.js';
    import { useNewsStore } from './../../stores/NewsStore.js';
    import Loader from './../../components/Loader.vue';
    import ErrorAxios  from './../../components/alerts/ErrorAxios.vue';
  
    export default {
        data() {
            return{
                data: ''
            }
        },
        components: {
            Loader,
            ErrorAxios
        },
        setup() {
            const { loading, error, currentArticles, moreArticles } = storeToRefs(useNewsStore())
    
            const { fetchArticles, timeConverter, loadMoreArticles } = useNewsStore();
    
            const { loadingPage} = storeToRefs(useStepsStore())
    
            return { loadingPage, loading, error, fetchArticles, timeConverter, loadMoreArticles, currentArticles, moreArticles };  
        },
        mounted(){
            axios
                .get('https://bo.pitstoplecce.it/index.php?r=page/page&id=3')
                .then(data => this.data = data.data)
                .catch((errors) => console.log(errors));

            this.fetchArticles();
            this.loadingPage = false;
        }
    }
</script>
  
<style scoped>
    .news-title{
        margin: 0 0 20px;
    }
    .news{
        margin: 60px auto 20px;
    }
    .news_article-block{
        margin: 30px 0 0;
        padding: 0 0 30px;
        border-bottom: 1px solid #707070;
    }
    .news_article{
        display: flex;
        align-items: flex-start;
    }
    .news_article-img{
        display: block;
        width: 265px;
        height: 180px;
        object-fit: cover;
    }
    .news_article-img img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 5px;
    }
    .news_article-items{
        display: flex;
        flex-direction: column;
        width: calc(100% - 300px);
        margin: 0 0 0 30px;
    }
    .news_article-block .news_data{
        margin: 10px 0 0;
        font-size: 20px;
        color: #707070;
    }
    .news_article-block .news_title{
        margin: 10px 0;
        color: var(--color_main_blue);
    }
    .news_article-block .news_text{
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
                line-clamp: 2; 
        -webkit-box-orient: vertical;
    }
    .news_article-block .news_text ::v-deep p{
        font-size: 20px;
        color: #1E201E;
    }
    .show-more_butt{
        display: block;
        margin: 30px 0 0;
        padding: 10px 20px;
        font-size: 24px;
        color: #fff;
        background: var(--color_main_blue);
        border-radius: 5px;
        float: right;
        text-transform: uppercase;
    }
</style>