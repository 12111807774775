<template>
  <footer>
    <div class="footer">
        <div class="footer_logos">
            <div class="footer_img">
                <img :src="logo1" alt="logo1">
            </div>
            <div class="footer_img">
                <img :src="logo2" alt="logo2">
            </div>
            <div class="footer_img">
                <img :src="logo3" alt="logo3">
            </div>
            <div class="footer_img">
                <img :src="logo4" alt="logo4">
            </div>
        </div>
        <p>Progetto finanziato dalla Prezidenza del Consiglio dei Ministri - Dipartimento per le politiche antidroga</p>
        <router-link to="/privacy" class="privacy-link">
            <span>Informativa sulla privacy</span>
        </router-link>
    </div>
  </footer>
</template>

<script>
export default {
    data() {
      return{
        logo1: "../assets/icons/logo01.svg",
        logo2: "../assets/icons/logo02.svg",
        logo3: "../assets/icons/logo03.svg",
        logo4: "../assets/icons/logo04.svg"
      }
    }
}
</script>

<style scoped>
    footer{
        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;
        width: 100vw;
        height: 300px;
        z-index: 13;
        background: #fff;
        box-shadow: 0px 0px 30px #50505041;
    }
    .footer{
        padding: 75px 55px 37px;
    }
    .footer_logos{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 0 60px;
        width: 100%;
        height: 100%;
        padding: 20px 0;
        border-top: 1px solid #000;
        border-bottom: 1px solid #000;
    }
    .footer_img{
        width: auto;
        max-width: 400px;
        height: 90px;
    }
    .footer_img img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    .footer p{
        margin: 20px auto 0;
        font-size: 10px;
        text-align: center;
    }
    .privacy-link{
        display: block;
        margin: 10px auto 0;
        font-size: 10px;
        text-align: center;
        color: #00A3D8;
    }
</style>