<template>
    <div class="page-padding">
        <div v-for="data in data" :key="data.id">
            <h1 class="privacy-title">{{ data.page_title }}</h1>
            <img :src="data.image_url" alt="">
            <div v-html="data.page_desc" class="privacy-text"></div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { useStepsStore } from '../stores/StepsStore.js';
import { storeToRefs } from 'pinia'

export default {
    data() {
        return{
            data: ''
        }
    },
    mounted(){
        axios
            .get('https://bo.pitstoplecce.it/index.php?r=page/page&id=4')
            .then(data => this.data = data.data)
            .catch((errors) => console.log(errors));

        this.loadingPage = false
    },
    setup() {
      const { loadingPage} = storeToRefs(useStepsStore())
      
      return {
        loadingPage
      };  
    }
}

</script>

<style>
    .privacy-title{
        margin: 0 0 20px;
    }

    .privacy-text p{
        margin: 0 0 15px;
        line-height: 20px;
        color: var(--color_grey-text);
    }
    .privacy-text br{
        margin: 0 0 10px;
    }

    .privacy-text p strong{
        display: inline-block;
        margin: 0 0 10px;
    }
</style>